<template>
<v-container>
  <v-dialog
    v-model="showPrintDialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        {{ $vuetify.lang.t('$vuetify.actions.print') }}
      </v-card-title>
      <v-card-actions>
        <v-btn
          class="mb-2"
          :disabled="isActionInProgress"
          @click="$emit('handleClose')"
        >
          <v-icon>mdi-close</v-icon>
          {{ smallScreen ? '':  $vuetify.lang.t('$vuetify.actions.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="mb-2"
          :disabled="isActionInProgress"
          @click="printFacture('ticket')"
        >
          <v-icon
            style="color: red"
            class="mr-2"
            small>
            mdi-printer
          </v-icon>
          {{ smallScreen ? '': $vuetify.lang.t("$vuetify.report.print_ticket") }}
        </v-btn>
        <v-spacer />
        <v-btn
          class="mb-2"
          color="primary"
          @click="printFacture('letter')"
        >
          <v-icon
            style="color: #1a5dd6"
            class="mr-2"
            small>
            mdi-printer-settings
          </v-icon>
          {{ smallScreen ? '':  $vuetify.lang.t("$vuetify.report.print_letter") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <print-facture v-if="showShowModal && printer === 'ticket'" :go="true"/>
  <print-facture-letter v-if="showShowModal && printer === 'letter'"  :go="true"/>
  <v-card-actions v-if="sale">
    <v-spacer />
    <v-btn
      class="mb-2"
      color="error"
      :disabled="isActionInProgress"
      @click="$emit('handleClose')"
    >
      <v-icon>mdi-close</v-icon>
      {{ smallScreen ? '':  $vuetify.lang.t('$vuetify.actions.cancel') }}
    </v-btn>
    <v-btn
      v-show="sale.state !== 'accepted'"
      class="mb-2"
      color="success"
      :disabled="isActionInProgress || !sale.box || sale.sale_article_shops.filter(art=>art.article_shops).length === 0"
      :loading="isActionInProgress"
      @click="saleHandler('open')"
    >
      <v-icon>mdi-check</v-icon>
      {{ smallScreen ? '':  $vuetify.lang.t('$vuetify.sale.state.open') }}
    </v-btn>
    <v-btn
      class="mb-2"
      color="primary"
      :disabled="isActionInProgress || !sale.box || sale.sale_article_shops.filter(art=>art.article_shops).length === 0"
      :loading="isActionInProgress"
      @click="saleHandler('accepted')"
    >
      <v-icon>mdi-check-all</v-icon>
      {{ smallScreen ? '': $vuetify.lang.t('$vuetify.sale.state.accepted') }}
    </v-btn>
  </v-card-actions>
</v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import PrintFacture from '../PrintFacture'
import PrintFactureLetter from '../PrintFactureLetter'

export default {
  name: 'SaleButtonsActions',
  components: { PrintFacture, PrintFactureLetter },
  data () {
    return {
      showPrintDialog: false,
      printer: 'ticket',
      formValid: false
    }
  },
  props: {
    sale: {
      type: Object,
      default: function () {
        return {}
      }
    },
    to_route: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState('sale', ['editSale', 'showShowModal', 'isActionInProgress', 'validSale']),
    ...mapGetters('statics', ['smallScreen']),
    ...mapGetters('auth', ['user']),
    getDifferencePay () {
      let totalCalcP = 0.00
      this.sale.payments.forEach(v => {
        totalCalcP += parseFloat(v.cant)
      })
      console.log('getDifferencePay')
      return parseFloat(parseFloat(this.sale.totalPrice) + parseFloat(this.sale.totalDelivery) + parseFloat(this.sale.totalPerquisite)) - parseFloat(totalCalcP)
    }
  },
  methods: {
    ...mapActions('sale', ['getSales', 'getNewSaleData', 'validCant', 'createSale', 'updateSale', 'fetchSaleNumber', 'openShowModal', 'getEditSale']),
    async saleHandler (state) {
      if (this.sale.payments.filter(py => py.bank_payment.method === 'credit').length > 0 && !this.sale.client) {
        this.loading = false
        this.shopMessageError(this.$vuetify.lang.t('$vuetify.messages.warning_no_client_credit'), 1)
        this.showExtraData = true
        this.showCartArticles = false
      } else if (parseFloat(this.getDifferencePay).toFixed(2) !== '0.00' && state === 'accepted') {
        this.loading = false
        this.shopMessageError(this.$vuetify.lang.t(
          '$vuetify.messages.warning_difference_price', [(-parseFloat(this.getDifferencePay).toFixed(2) + ' ' + this.user.company.currency).toString()]
        ), 1)
        this.showExtraData = true
        this.showCartArticles = false
      } else {
        if (!this.sale.online && !this.sale.box) {
          this.loading = false
          this.shopMessageError(this.$vuetify.lang.t(
            '$vuetify.messages.warning_no_box'
          ), 1)
        } else {
          if (this.sale.sale_article_shops.length > 0) {
            this.loading = true
            if (state === 'accepted') {
              await this.validCant({ cant: this.sale.totalPrice + this.sale.totalDelivery + this.sale.totalPerquisite }).then(() => {
                if (!this.validSale.valid) {
                  this.$Swal.fire({
                    title: this.$vuetify.lang.t('$vuetify.titles.newF', [
                      this.$vuetify.lang.t('$vuetify.sale.sale')
                    ]),
                    text: this.$vuetify.lang.t('$vuetify.messages.invalid_cant', [parseFloat(this.validSale.basic_limit).toFixed(2)],
                      [parseFloat(this.validSale.total_facture).toFixed(2)], [parseFloat(parseFloat(this.validSale.total_facture) + parseFloat(this.sale.totalPrice + this.sale.totalDelivery + this.sale.totalPerquisite)).toFixed(2)]),
                    icon: 'error',
                    showCancelButton: true,
                    confirmButtonText: this.$vuetify.lang.t(
                      '$vuetify.menu.my_subscription'
                    ),
                    cancelButtonTextButtonCancel: this.$vuetify.lang.t(
                      '$vuetify.actions.cancel'
                    ),
                    confirmButtonColor: 'primary'
                  })
                    .then(result => {
                      if (result.isConfirmed) this.$router.push({ name: 'my_subscription' })
                    })
                } else {
                  this.gestSale(state)
                }
              })
            } else { this.gestSale(state) }
          } else {
            this.loading = false
            this.shopMessageError(this.$vuetify.lang.t(
              '$vuetify.messages.warning_cant_article'
            ), 1)
          }
        }
      }
    },
    async gestSale (state) {
      this.sale.state = state
      if (!this.sale.id) {
        await this.createSale(this.sale).then(() => {
          if (state === 'accepted' && this.to_route !== 'hidden') {
            this.showPrintDialog = true
          } else {
            if (this.sale.state === 'accepted') {
              this.$router.push({ name: 'vending_accepted' })
            } else if (this.sale.state === 'open') {
              this.$router.push({ name: 'vending_process' })
            } else {
              this.$router.push({ name: 'vending' })
            }
          }
        })
      } else {
        await this.updateSale(this.sale).then(() => {
          if (state === 'accepted' && this.to_route !== 'hidden') {
            this.showPrintDialog = true
          } else {
            if (this.sale.state === 'accepted') {
              this.$router.push({ name: 'vending_acepted' })
            } else if (this.sale.state === 'open') {
              this.$router.push({ name: 'vending_process' })
            } else {
              this.$router.push({ name: 'vending' })
            }
          }
        })
      }
    },
    printFacture (type) {
      this.printer = type
      this.openShowModal(this.editSale.id)
    },
    shopMessageError (message, active) {
      this.$Swal.fire({
        title: this.$vuetify.lang.t('$vuetify.titles.newF', [
          this.$vuetify.lang.t('$vuetify.sale.sale')
        ]),
        text: message,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: this.$vuetify.lang.t(
          '$vuetify.actions.accept'
        ),
        confirmButtonColor: 'red'
      })
        .then(result => {
          if (result.isConfirmed) this.panel = active
        })
    }
  }
}
</script>

<style scoped>

</style>
