<template>
  <v-container>
    <v-dialog v-model="showVariantCost" max-width="400">
      <v-card>
        <v-card-subtitle>
          {{$vuetify.lang.t('$vuetify.rule.select')}}
        </v-card-subtitle>
        <v-card-text>
          <v-select
            v-model="variantCost"
            :items="variantCosts"
            :item-text="'ref'"
            return-object
            :label="$vuetify.lang.t('$vuetify.rule.select')"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showVariantCost=false" color="error">
            {{$vuetify.lang.t('$vuetify.actions.cancel')}}
          </v-btn>
          <v-btn @click="sendUpdateArticleSale" color="primary">
            {{$vuetify.lang.t('$vuetify.actions.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
    >
      <v-tab
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card
          color="basil"
          flat
        >
          <div :style="articleShops.variant_values.length === 0 ?'cursor: pointer;': ''" class="d-flex" @click="articleShops.variant_values.length === 0 ? addElementToSale(articleShops): ''">
            <div v-if="articleShops.article.images.length > 0"
                 class="flex pa-3"
                 style="width: 30%"
            >
              <div class="layout justify-center align-center" style="height: 120px">
                <v-img
                  height="100"
                  :src="articleShops.article.images.length > 0 ? articleShops.article.images[0].original_url : ''"
                >
                </v-img>
              </div>
            </div>
            <div
              style="width: 70%"
              :class="color"
              class="flex pa-3"
            >
              <span class="white--text align-center">{{ UpperCaseFirstLetter(articleShops) }}</span><br/>
              <v-card v-if="articleShops.variant_values.length === 0" :color="color">
                <span class="white--text align-center" v-if="!online"> {{ $vuetify.lang.t('$vuetify.price') + ': ' + parseFloat(articleShops.price).toFixed(2) + ' ' + currency }}</span>
                <span class="white--text align-center" v-else> {{ $vuetify.lang.t('$vuetify.price') + ': ' + parseFloat(articleShops.totalOnlinePrice).toFixed(2) + ' ' + currency}}</span>
                <br/>
                <span class="white--text align-center"> {{ $vuetify.lang.t('$vuetify.articles.taxes') + ': ' + parseFloat(articleShops.totalTax).toFixed(2) + ' ' + currency}}</span><br/>
                <span class="white--text align-center"  v-if="!online"> {{ $vuetify.lang.t('$vuetify.variants.total_price') + ': ' + parseFloat(parseFloat(articleShops.price) + parseFloat(articleShops.totalTax)).toFixed(2) + ' ' + currency}}</span>
                <span class="white--text align-center" v-else> {{ $vuetify.lang.t('$vuetify.variants.total_price') + ': ' + parseFloat(articleShops.totalOnlinePrice) + parseFloat(articleShops.totalTax).toFixed(2) + ' ' + currency}}</span>
              </v-card>
            </div>
          </div>
          <v-expansion-panels v-if="articleShops.variant_values.length">
            <v-expansion-panel v-if="extractVariantValues(articleShops).length > 0">
              <v-expansion-panel-header>
                {{$vuetify.lang.t('$vuetify.panel.variant')}}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="scroll" style="height: 400px">
                <v-item-group mandatory>
                  <v-row>
                    <v-col  v-for="(artS, i) in extractVariantValues(articleShops)" :key="i" cols="12" md="12" @click="addElementToSale(artS)">
                      <v-item v-slot="{ active, toggle }" style="margin-bottom: 10px">
                        <v-sheet
                          rounded="xl"
                          :color="active ? 'primary' : '#5F5A5AA8'"
                          class="d-flex align-center"
                          dark
                          height="190"
                          width="100%"
                          @click="toggle"
                        >
                          <div width="100%" style="cursor: pointer;height: 120px;">
                            <span class="align-center">{{ UpperCaseFirstLetter(artS) }}</span><br/>
                            <span class="align-center"> {{ $vuetify.lang.t('$vuetify.price') + ': ' + currency + ' ' + artS.price }}</span><br/>
                            <span class="align-center"> {{ $vuetify.lang.t('$vuetify.articles.taxes') + ': ' + currency + ' ' + parseFloat(artS.totalTax).toFixed(2) }}</span><br/>
                            <span class="align-center"> {{ $vuetify.lang.t('$vuetify.variants.total_price') + ': ' + currency + ' ' + parseFloat(parseFloat(artS.price) + parseFloat(artS.totalTax)).toFixed(2) }}</span><br/>
                            <span class="align-center"> {{ $vuetify.lang.t('$vuetify.articles.inventory') + ':  ' + parseFloat(artS.inventory).toFixed(2) }}</span>
                          </div>
                        </v-sheet>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <p v-else>Sus variantes tienen precio cero(0). No puede ser vendido</p>
          </v-expansion-panels>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          color="basil"
          flat
        >
          <v-card-text>
            <v-autocomplete
              v-if="articleShops.variant_values.length > 0"
              v-model="variant"
              :items="extractVariantValues(articleShops)"
              auto-select-first
              return-object
              item-text="article.name"/>
            <v-text-field-money
              v-model="cant"
              :label="$vuetify.lang.t('$vuetify.variants.cant')"
              :options="{
                length: 15,
                precision: 2,
                empty: 0.0,
                min:0.00
              }"
              :properties="{
                prefix: currency,
                min:0.00,
              }"
              style="margin-top: 8px"
            />
          </v-card-text>
          <v-card-actions>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="cant !== 0.00 && variant"
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="calcCantByMoney"
                >
                  <v-icon>
                    mdi-cart-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>{{$vuetify.lang.t('$vuetify.sale.add_cart')}}</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  name: 'ArticleSale',
  props: {
    articleShops: {
      type: Object,
      default: function () {
        return {}
      }
    },
    online: {
      type: Boolean,
      default: false
    },
    currency: {
      type: String,
      default: '$'
    },
    saleArticleShop: {
      type: Array,
      default: function () {
        return []
      }
    },
    color: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      showVariantCost: false,
      artS: null,
      variantCost: null,
      variantCosts: [],
      variant: null,
      cant: 0.00,
      tab: null,
      items: [
        this.$vuetify.lang.t('$vuetify.menu.article'), this.$vuetify.lang.t('$vuetify.menu.detail')
      ]
    }
  },
  watch: {
    'articleShop.variant_values': function () {
      this.variant = this.articleShop.variant_values[0]
    }
  },
  methods: {
    addElementToSale (artS) {
      if (artS.variant_cost.length === 1) { this.$emit('addToSale', artS, artS.variant_cost[0]) } else {
        this.artS = artS
        this.showVariantCost = true
        this.variantCosts = artS.variant_cost
      }
    },
    sendUpdateArticleSale () {
      this.$emit('addToSale', this.artS, this.variantCost)
      this.showVariantCost = false
    },
    extractVariantValues (articleS) {
      console.log(articleS.variant_values.filter(vv => parseFloat(vv.price) > 0 && vv.shop_id === this.articleShops.shop_id))
      if (!this.online) {
        return articleS.variant_values.filter(vv => parseFloat(vv.price) > 0 && vv.shop_id === this.articleShops.shop_id)
      } else {
        return articleS.variant_values.filter(vv => parseFloat(vv.online_price) > 0.00)
      }
    },
    UpperCaseFirstLetter (artS) {
      const str = artS.article.name
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    },
    calcCantByMoney () {
      let cantPay = Math.round((this.cant / (this.online ? this.articleShops.totalOnlinePrice : this.articleShops.totalPrice)) * 100) / 100
      let sum = this.cant
      while (cantPay * this.articleShops.article.totalPrice > sum) {
        // eslint-disable-next-line no-const-assign
        const rest = Math.round(((cantPay * (this.online ? this.articleShops.totalOnlinePrice : this.articleShops.totalPrice)) - this.cant) * 100) / 100
        sum = sum - rest
        cantPay = Math.round(sum / (this.online ? this.articleShops.totalOnlinePrice : this.articleShops.totalPrice) * 100) / 100
      }
      this.articleShops.cant = cantPay
      this.cant = cantPay * (this.online ? this.articleShops.totalOnlinePrice : this.articleShops.totalPrice)
      this.$emit('addToSale', this.variant)
    }
  }
}
</script>

<style scoped>
.scroll {
  overflow-y: scroll
}
</style>

<style scoped lang="scss">
@import url(https://fonts.googleapis.com/css?family=Bitter:400,400italic,700);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Bitter";
}

$mobile: 600px;
$max-width: 80em;

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@keyframes openUp {
  0% {
    transform: translate(-50%, -50%) scale(0.5);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

button {
  appearance: none;
  padding: 0.5em;
  margin: 0.5em 0;
  background: white;
  border: 1px solid black;
  transition: all 0.1s;
  font-size: 14px;
  cursor: pointer;
  user-select: none;

  .fa {
    font-size: 1em !important;
    vertical-align: middle;
  }

  &:hover {
    color: white;
    background: black;
  }
}

input {
  display: inline-block;
  appearance: none;
  padding: 0.5em;
  margin: 0.5em 0.5em 0.5em 0;
  width: 50px;
  background: white;
  border: 1px solid black;
  transition: all 0.1s;
  font-size: 14px;
}

label {
  font-size: 0.75em;
  margin-right: 0.5em;
}

.checkout-area table {
  margin: 0 auto;
  padding: 0.5em;
  width: 100%;
  max-width: $max-width * 0.5;
  text-align: left;

  th, td {
    padding: 0 0.25em;
  }

  @media(max-width: $mobile) {
    th:nth-child(3), td:nth-child(3) {
      display: none;
    }
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.vert-bottom {
  vertical-align: bottom;
}

.vert-middle {
  vertical-align: middle;
}

.main-wrapper {
  .header {
    position: relative;
    background: linear-gradient(to left, #16222A, #3A6073);
    background-size: cover;
    height: 25em;
    width: 100vw;
    box-shadow: inset -1px -3px 5px rgba(0, 0, 0, 0.5), inset 1px 3px 5px rgba(0, 0, 0, 0.5);

    h1 {
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 3em;
      text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.5), -1px -3px 5px rgba(0, 0, 0, 0.5);
    }
  }

  #vue {
    margin: 0 auto;
    padding: 0.5em;
    text-align: center;

    .cart {
      position: fixed;
      right: 0em;
      top: 0em;
      text-align: right;
      background: rgba(0, 0, 0, 0.85);
      color: white;
      z-index: 1;

      .fa-shopping-cart, .cart-size {
        cursor: pointer;
        font-size: 1.25em;
        user-select: none;
      }

      .fa-shopping-cart {
        padding: 1em 1em 1em 0;
      }

      .cart-size {
        padding: 1em 0 1em 1em;
      }

      .cart-items {
        padding: 0 1em 2em 1em;

        .cartTable {
          width: 20em;
        }

        .cartImage {
          width: 4em;
          height: 4em;
          margin: 0.5em auto;
          position: relative;
          cursor: pointer;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.25);
            transition: all 0.1s;
          }

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.5em;
            z-index: 1;
            transition: all 0.25s;
          }

          &:hover {
            i {
              text-shadow: 1px 2px 5px black;
            }

            &:after {
              background: rgba(0, 0, 0, 0.5);
            }
          }

          &:active {
            i {
              text-shadow: 0px 0px 1px black;
            }
          }
        }

        .cartSubTotal {
          border-top: 1px solid white;
          font-size: 1.25em;
        }

        .clearCart {
          float: left;
          margin-top: 2em;
          margin-bottom: 1.25em;
        }

        .checkoutCart {
          float: right;
          margin-top: 2em;
          margin-bottom: 1.25em;
        }
      }
    }

    .products {
      margin: 0 auto;
      width: 100%;
      max-width: $max-width;

      .product {
        display: inline-block;
        margin: 0.75em;
        width: 100%;
        max-width: 18em;

        .image {
          width: 18em;
          height: 18em;
          margin-bottom: 0.5em;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          transition: box-shadow 0.25s;

          @media(max-width: $mobile) {
            width: 100vw;
            height: 100vw;
          }

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0;
            transition: all 0.25s;
          }

          &:after {
            content: "\f00e";
            font-family: "FontAwesome";
            position: absolute;
            top: 250%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 5em;
            color: white;
            pointer-events: none;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.5), 0 0 10px rgba(0, 0, 0, 0.5);
            transition: all 0.25s;
          }

          &:hover {
            box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);

            &:before {
              opacity: 1;
            }

            &:after {
              top: 50%;
            }
          }
        }

        .name {
          font-weight: bold;
          font-size: 1.25em;
        }

        .description {
          font-style: italic;
        }

        .price {
          font-weight: bold;
        }
      }
    }

    .modalWrapper {
      position: relative;

      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        //cursor: pointer;
        z-index: 2;
      }

      .prevProduct, .nextProduct {
        position: fixed;
        color: white;
        font-size: 2em;
        cursor: pointer;
        top: 50%;
        transform: translateY(-50%);
        padding: 1em;
        user-select: none;
        z-index: 5;
      }

      .prevProduct {
        left: calc(50% - 9.5em);
      }

      .nextProduct {
        right: calc(50% - 9.5em);
      }

      .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 2em;
        text-align: center;
        max-height: calc(100% - 2em);
        overflow-y: scroll;
        overflow-x: hidden;
        animation: openUp 0.1s;
        z-index: 3;
        backface-visibility: hidden;

        .close {
          position: fixed;
          top: -0.5em;
          right: -0.5em;
          cursor: pointer;
          padding: 1em;
        }

        .imageWrapper {
          width: 25em;
          height: 25em;
          margin: 0.5em auto;
          overflow: hidden;

          .image {
            width: 100%;
            height: 100%;
            transition: transform 0.2s;
            z-index: 4;
            cursor: crosshair;
          }
        }

        .additionalImages {
          display: flex;
          width: 100%;
          margin: 0 auto;
          text-align: center;

          .additionalImage {
            flex-grow: 1;
            margin: 0.5em 1em;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            width: 25%;
            height: auto;
            @include aspect-ratio(16, 12);

            &:nth-of-type(1n) {
              margin-left: 0;
            }

            &:nth-of-type(4n) {
              margin-right: 0;
            }
          }
        }

        .name {
          font-weight: bold;
          font-size: 1.25em;
        }

        .description {
          font-style: italic;
        }

        .details {
          max-width: 25em;
          margin: 0 auto;
          padding: 0.5em 0;
        }

        .price {
          font-weight: bold;
          padding-bottom: 0.5em;
        }
      }
    }

    @media(max-width: $mobile) {
      .cart {
        &, & .cartTable {
          width: 100% !important;
        }
      }

      .products {
        text-align: left;

        .product {
          display: block;

          .image {
            width: calc(100vw - 2.5em);
            height: calc(100vw - 2.5em);
          }
        }
      }

      .modalWrapper {
        .prevProduct, .nextProduct {
          display: none;
        }

        .modal {
          &.checkout {
            width: 100%;
          }

          .imageWrapper {
            width: calc(100vw - 4em);
            height: calc(100vw - 4em);
          }
        }
      }
    }
  }
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
</style>
