<template>
  <v-container>
    <new-client v-if="$store.state.client.showNewModal"/>
    <new-tax v-if="$store.state.tax.showNewModal"/>
    <new-payment v-if="$store.state.payment.showNewModal"/>
    <new-discount v-if="this.$store.state.discount.showNewModal"/>
    <v-card-title>
      <v-icon @click="$emit('cancelExtraData')">
        mdi-arrow-left-bold-circle
      </v-icon>
      {{ $vuetify.lang.t('$vuetify.pay.extra_data') }}
    </v-card-title>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-autocomplete
          style="margin-top: 15px"
          v-model="sale.client"
          clearable
          :items="clients"
          :label="$vuetify.lang.t('$vuetify.menu.client')"
          item-text="firstName"
          :loading="isClientTableLoading"
          :disabled="!!isClientTableLoading"
          return-object
        >
          <template v-slot:append-outer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    $store.dispatch(
                      'client/toogleNewModal',
                      true
                    )
                  "
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>{{
                  $vuetify.lang.t("$vuetify.titles.newAction")
                }}</span>
            </v-tooltip>
          </template>
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              @click="data.select"
            >
              <v-avatar left>
                <v-img
                  :src="
                    data.item.avatar ||
                      '/assets/avatar/avatar-undefined.jpg'
                  "
                />
              </v-avatar>
              {{
                data.item.first_name +
                " " +
                `${
                  data.item.last_name !== null
                    ? data.item.last_name
                    : ""
                }`
              }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-avatar>
                <v-img
                  :src="
                    data.item.avatar ||
                      '/assets/avatar/avatar-undefined.jpg'
                  "
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    data.item.first_name +
                    " " +
                    `${
                      data.item.last_name !== null
                        ? data.item.last_name
                        : ""
                    }`
                  }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="data.item.email">
                  {{ `${data.item.email}` }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-text-field
          style="margin-top: 15px"
          v-model="sale.no_facture"
          :label="$vuetify.lang.t('$vuetify.tax.noFacture')"
          required
          readonly
          :rules="formRule.required"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-select
          v-model="sale.taxes"
          chips
          clearable
          deletable-chips
          :items="taxes"
          multiple
          :label="$vuetify.lang.t('$vuetify.tax.nameGeneral')"
          item-text="name"
          return-object
          required
          :rules="formRule.country"
          @change="updateData"
        >
          <template v-slot:append-outer v-if="access_permit.tax.actions.create">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="$store.dispatch('tax/toogleNewModal', true)"
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.titles.newAction") }}</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-select
          v-model="sale.discounts"
          chips
          clearable
          deletable-chips
          :items="discounts"
          multiple
          :label="$vuetify.lang.t('$vuetify.sale.discountGeneral')"
          item-text="name"
          return-object
          @change="updateData"
        >
          <template v-slot:append-outer v-if="access_permit.discount.actions.create">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="$store.dispatch('discount/toogleNewModal', true)"
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.titles.newAction") }}</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-select
          v-model="sale.delivery"
          chips
          deletable-chips
          :items="deliveries"
          :label="$vuetify.lang.t('$vuetify.menu.delivery')"
          item-text="name"
          return-object
          @change="updateData"
        >
          <template v-slot:append-outer v-if="access_permit.delivery.actions.create">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="$store.dispatch('delivery/toogleNewModal', true)"
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.titles.newAction") }}</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        md="3"
      >
        <v-select
          v-model="sale.perquisite"
          chips
          deletable-chips
          :items="perquisites"
          :label="$vuetify.lang.t('$vuetify.menu.perquisite')"
          item-text="name"
          return-object
          @change="updateData"
        >
          <template v-slot:append-outer v-if="access_permit.perquisite.actions.create">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="$store.dispatch('perquisite/toogleNewModal', true)"
                >
                  mdi-plus
                </v-icon>
              </template>
              <span>{{ $vuetify.lang.t("$vuetify.titles.newAction") }}</span>
            </v-tooltip>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <list-pay
          :online="online"
          :online-config="onlineConfig"
          :edit="edit"
          :sale="sale"
          :total-price="parseFloat(localTotalPrice).toFixed(2)"
          :total-tax="parseFloat(localTotalTax).toFixed(2)"
          :total-discount="parseFloat(localTotalDiscounts).toFixed(2)"
          :total-delivery="parseFloat(localTotalDelivery).toFixed(2)"
          :total-perquisite="parseFloat(localTotalPerquisite).toFixed(2)"
          :discounts="localDiscounts"
          :sub-total="subTotal ? parseFloat(subTotal).toFixed(2) : 0.0"
          :currency="user.company.currency"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewTax from '../../../tax/commerce/NewTax'
import NewDiscount from '../../../discount/commerce/NewDiscount'
import NewClient from '../../../client/commerce/NewClient'
import NewPayment from '../../../payment/commerce/NewPayment'
import ListPay from '../../../pay/ListPay'

export default {
  name: 'ExtraData',
  components: { ListPay, NewPayment, NewTax, NewClient, NewDiscount },
  props: {
    fromList: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    online: {
      type: Boolean,
      default: false
    },
    onlineConfig: {
      type: Object,
      default: function () {
        return {}
      }
    },
    sale: {
      type: Object,
      default: function () {
        return {}
      }
    },
    totalPrice: {
      type: String,
      default: '0.00'
    },
    totalDelivery: {
      type: String,
      default: '0.00'
    },
    totalPerquisite: {
      type: String,
      default: '0.00'
    },
    totalTax: {
      type: String,
      default: '0.00'
    },
    totalDiscount: {
      type: String,
      default: '0.00'
    },
    subTotal: {
      type: String,
      default: '0.00'
    },
    taxes: {
      type: Array,
      default: function () {
        return []
      }
    },
    discounts: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      formRule: this.$rules,
      localTotalPrice: this.totalPrice,
      localTotalTax: this.totalTax,
      localTotalDiscounts: this.totalDiscount,
      localTotalDelivery: this.totalDelivery,
      localTotalPerquisite: this.totalPerquisite,
      localDiscounts: []
    }
  },
  computed: {
    ...mapState('client', ['clients', 'isClientTableLoading']),
    ...mapState('delivery', ['deliveries']),
    ...mapState('perquisite', ['perquisites']),
    ...mapState('payment', ['payments', 'isPaymentLoading']),
    ...mapState('sale', ['newSale', 'editSale']),
    ...mapGetters('auth', ['user', 'access_permit'])
  },
  watch: {
    totalPrice: function () {
      this.localTotalPrice = this.totalPrice
    },
    totalTax: function () {
      this.localTotalTax = this.totalTax
    },
    totalDiscounts: function () {
      this.localTotalDiscounts = this.totalDiscounts
    },
    totalDelivery: function () {
      this.localTotalDelivery = this.totalDelivery
    },
    totalPerquisite: function () {
      this.localTotalPerquisite = this.totalPerquisite
    }
  },
  async created () {
    console.log(this.fromList)
    await this.getClients().then(() => {
      if (this.sale.client) {
        this.clients.forEach((cl) => {
          if (this.sale.client.id === cl.id) {
            this.sale.client = cl
          }
        })
      }
    })
    await this.getDeliveries()
    await this.getPerquisites()
    await this.getTaxes()
    await this.getPayments()
  },
  methods: {
    ...mapActions('client', ['getClients']),
    ...mapActions('delivery', ['getDeliveries']),
    ...mapActions('perquisite', ['getPerquisites']),
    ...mapActions('tax', ['getTaxes']),
    ...mapActions('payment', ['getPayments']),
    updateData () {
      if (this.fromList) {
        this.calcTotalSale()
      } else {
        this.$emit('updateData')
      }
    },
    calcTotalSale () {
      this.sale.totalTax = 0
      this.sale.totalDiscount = 0
      this.sale.discounts.forEach((v) => {
        this.sale.totalDiscount += v.percent === 'true' ? this.subTotal * v.cant / 100 : v.cant
      })
      this.localTotalDiscounts = this.sale.totalDiscount
      this.sale.taxes.forEach((v) => {
        this.sale.totalTax += v.percent === 'true' ? (parseFloat(this.subTotal) - parseFloat(this.sale.totalDiscount)) * v.cant / 100 : v.cant
      })
      this.localTotalTax = this.sale.totalTax
      this.localTotalDelivery = this.sale.delivery ? this.sale.delivery.percent === 'true' ? (parseFloat(this.subTotal) - parseFloat(this.localTotalDiscounts)) * this.sale.delivery.cant / 100 : this.sale.delivery.cant : 0
      this.localTotalPerquisite = this.sale.perquisite ? this.sale.perquisite.percent === 'true' ? (parseFloat(this.subTotal) - parseFloat(this.localTotalDiscounts)) * this.sale.perquisite.cant / 100 : this.sale.perquisite.cant : 0
      this.sale.totalDelivery = this.localTotalDelivery
      this.sale.totalPerquisite = this.localTotalPerquisite
      this.sale.totalPrice = parseFloat(parseFloat(this.subTotal) + parseFloat(this.sale.totalTax) - parseFloat(this.sale.totalDiscount)).toFixed(2)
      this.localTotalPrice = this.sale.totalPrice
    }
  }
}
</script>

<style scoped>

</style>
