<template>
  <no-subcription v-if="!getSubscription"/>
  <v-container v-else>
    <v-card v-if="!loadingData && cantBox === 0">
      <v-card-text>
        {{$vuetify.lang.t('$vuetify.messages.warning_no_boxes')}}
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$router.push({ name: 'boxes' })">{{$vuetify.lang.t(
          '$vuetify.actions.goTo', this.$vuetify.lang.t('$vuetify.menu.box')
        )}}</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="!loadingData && !existArticles">
      <v-card-text>
        {{$vuetify.lang.t('$vuetify.messages.warning_no_articles')}}
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$router.push({ name: 'product_add' })">{{$vuetify.lang.t(
          '$vuetify.actions.goTo', this.$vuetify.lang.t('$vuetify.menu.articles')
        )}}</v-btn>
      </v-card-actions>
    </v-card>
    <app-loading v-show="loadingData"/>
    <v-card v-if="!loadingData && existArticles && cantBox"
    >
      <v-card-text>

        <v-expansion-panels
          v-model="panels"
          multiple
        >
          <v-expansion-panel>
            <v-expansion-panel-header  disable-icon-rotate>
              {{$vuetify.lang.t('$vuetify.panel.basic')}}
              <template v-slot:actions>
                <v-icon color="primary">
                  mdi-check-circle
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-form
                ref="form"
                v-model="formValid"
                style="padding: 0"
                lazy-validation
              >

                <v-row>
                  <v-col class="col-m-d-12 col-sm-12 col-xs-12">
                    <v-row>
                      <v-col md="4" cols="12">
                        <v-autocomplete
                          :label="$vuetify.lang.t('$vuetify.menu.shop')"
                          v-model="sale.shop"
                          style="margin-left: 10px"
                          :disabled="!managerSale"
                          :items="shops"
                          item-text="name"
                          :loading="isActionInProgress"
                          return-object
                          required
                          :rules="formRule.country"
                          @change="changeShop"
                        />
                      </v-col>
                      <v-col md="4" cols="12">
                        <v-autocomplete
                          :label="$vuetify.lang.t('$vuetify.menu.type_order')"
                          v-model="sale.type_order"
                          style="margin-left: 10px"
                          :items="sale.shop.type_orders"
                          item-text="name"
                          :loading="isActionInProgress"
                          return-object
                        >
                          <template v-slot:append-outer v-if="access_permit.type_of_order.actions.create">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="$store.dispatch('typeOrder/toogleNewModal', true)"
                                >
                                  mdi-plus
                                </v-icon>
                              </template>
                              <span>{{ $vuetify.lang.t("$vuetify.titles.newAction") }}</span>
                            </v-tooltip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-width="200"
                          offset-y
                          transition="fab-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              clearable
                              style="margin-top: 15px;margin-left: 15px;"
                              :label="$vuetify.lang.t('$vuetify.menu.category')"
                              v-bind="attrs"
                              v-on="on"
                              :value="getCategoryName"
                              readonly
                              @click:clear="clearCategory"
                            >
                            </v-text-field>
                          </template>
                          <v-card max-height="250">
                            <v-sheet class="pa-4 primary lighten-2">
                              <v-text-field
                                v-model="search"
                                :label="$vuetify.lang.t('$vuetify.actions.search')"
                                dark
                                flat
                                ref="search"
                                :autofocus="true"
                                solo-inverted
                                hide-details
                                clearable
                                clear-icon="mdi-close-circle-outline"
                              ></v-text-field>
                            </v-sheet>
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-treeview
                                    activatable
                                    :search="search"
                                    :filter="filter"
                                    :items="getCategoriesLanguege"
                                    item-text="category"
                                  >
                                    <template v-slot:label="{ item }">
                                      <v-list-item two-line @click="selectCategory(item)">
                                        <v-list-item-icon v-if="item.id !== ''">
                                          <v-spacer />
                                          <v-divider />
                                          <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-icon
                                                style="margin-top: 10px"
                                                class="mr-2"
                                                small
                                                color="primary"
                                                v-bind="attrs"
                                                v-on="on"
                                              >
                                                mdi-tag
                                              </v-icon>
                                            </template>
                                            <span>{{ $vuetify.lang.t('$vuetify.component.select_one') }}</span>
                                          </v-tooltip>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                                                              <span
                                                                                v-if="item.id"
                                                                                class="subtitle-2 font-weight-light"
                                                                              >
                                                                                {{!item.company_id ?  UpperCaseFirstLetter(item.category) : item.category }}
                                                                              </span>
                                        </v-list-item-title>
                                      </v-list-item>
                                    </template>
                                  </v-treeview>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </v-col>
                      <v-col
                        cols="12" md="4"
                      >
                        <v-autocomplete
                          id="slc_box"
                          v-model="sale.box"
                          style="margin-left: 15px;margin-top: 15px"
                          :rules="formRule.country"
                          :items="sale.shop.boxes.filter(bx =>!bx.digital)"
                          :disabled="!managerSale"
                          required
                          auto-select-first
                          :label="$vuetify.lang.t('$vuetify.menu.box')"
                          item-text="name"
                          return-object
                        >
                          <template v-slot:append-outer v-if="access_permit.box.actions.create">
                              <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="$store.dispatch('boxes/toogleNewModal', true)"
                                      >
                                          mdi-plus
                                      </v-icon>
                                  </template>
                                  <span>{{ $vuetify.lang.t("$vuetify.titles.newAction") }}</span>
                              </v-tooltip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col v-if="loadingArticle" cols="12">
                        <app-loading v-if="loadingArticle" :loading-text="$vuetify.lang.t('$vuetify.messages.wait.loadingArticle')"/>
                      </v-col>
                      <v-col cols="12" v-if="!loadingArticle && showCart">
                        <v-toolbar flat>
                          <v-toolbar-title @click="showListArticles = !showListArticles">
                            <v-tooltip bottom v-if="!showListArticles">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  dark
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-eye
                                </v-icon>
                              </template>
                              <span>{{ $vuetify.lang.t('$vuetify.actions.show') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="showListArticles">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  dark
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-eye-off
                                </v-icon>
                              </template>
                              <span>{{ $vuetify.lang.t('$vuetify.actions.hidden') }}</span>
                            </v-tooltip>
                            {{$vuetify.lang.t('$vuetify.titles.list', [$vuetify.lang.t('$vuetify.menu.articles')])}}
                          </v-toolbar-title>
                          <v-divider
                            class="mx-4"
                            inset
                            vertical
                          ></v-divider>
                          <v-spacer/>
                          <template v-if="sale.sale_article_shops.length > 0">
                            <v-tooltip bottom v-if="!showCart">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  dark
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="showCart = true"
                                >
                                  mdi-eye
                                </v-icon>
                              </template>
                              <span>{{ $vuetify.lang.t('$vuetify.actions.show') }}</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="showCart">
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  color="primary"
                                  dark
                                  small
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="showCart = false"
                                >
                                  mdi-eye-off
                                </v-icon>
                              </template>
                              <span>{{ $vuetify.lang.t('$vuetify.actions.hidden') }}</span>
                            </v-tooltip>
                            {{$vuetify.lang.t('$vuetify.actions.see', [$vuetify.lang.t('$vuetify.sale.cart')])}}
                          </template>
                        </v-toolbar>
                      </v-col>
                      <v-col cols="12" v-if="!loadingArticle && showListArticles">
                        <v-row
                          dense
                        >
                          <v-col
                            cols="12"
                            md="6"
                            sm="8"
                            class="pl-6 pt-6"
                          >
                            <small>{{ $vuetify.lang.t('$vuetify.online.showing') }} {{
                                page * 9 + 1
                              }}-{{
                                articlesFilter.length > 9*(page +1) ? 9*(page + 1) : articlesFilter.length
                              }} {{ $vuetify.lang.t('$vuetify.online.of') }} {{
                                articlesFilter.length
                              }}</small>
                          </v-col>
                          <v-col md="6" col="12">
                            <v-text-field
                              v-model="txtFilter"
                              :label="$vuetify.lang.t('$vuetify.actions.search')"
                              prepend-inner-icon="mdi-magnify"
                              class="ml-4"
                            />
                          </v-col>
                        </v-row>
                        <v-divider />
                        <v-row
                          v-if="articlesFilter.length > 0">
                          <v-col
                            v-for="item in articlesFilter.slice(page*12, 12*(page + 1))"
                            :key="item.id"
                            cols="12"
                            md="4"
                          >
                            <article-sale
                              color="primary"
                              :article-shops="item"
                              :currency="getCurrency"
                              @addToSale="selectArticle"
                            />
                          </v-col>
                        </v-row>
                        <div
                          v-if="articlesFilter.length > 12"
                          class="text-center mt-12"
                        >
                          <v-pagination
                            v-model="page"
                            :length="Math.round(articlesFilter.length/12 -1)"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="col-m-d-12 col-sm-12 col-xs-12"  v-if="sale.sale_article_shops.length > 0 && showCart">
                    <cart-article
                      :edit="managerSale"
                      :sale="sale"
                      :discounts="localDiscounts"
                      :modifiers="sale.shop.modifiers"
                      :taxes="getNewData.tax ? getNewData.tax.length > taxes.length ? getNewData.tax : taxes : taxes"
                      :total-price="parseFloat(sale.totalPrice).toFixed(2)"
                      :total-tax="parseFloat(sale.totalTax).toFixed(2)"
                      :total-discount="parseFloat(sale.totalDiscount).toFixed(2)"
                      :sub-total="parseFloat(sale.subTotal).toFixed(2)"
                      :logo="user.company.logo"
                      @cancelCartArticle="showCartArticles=false"
                      @updateArticle="articleCalcTotalPrice"
                      @updateSaleData="updateSaleData"
                      @clearArticles="clearArticles"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="sale.sale_article_shops.length > 0">
            <v-expansion-panel-header disable-icon-rotate>
              {{$vuetify.lang.t('$vuetify.sale.pay_detail')}}
              <template v-slot:actions>
                <v-icon color="primary">
                  mdi-menu
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="sale.sale_article_shops.length > 0">
              <extra-data
                :edit="managerSale"
                :sale="sale"
                :taxes="localTaxes"
                :discounts="localDiscounts"
                :total-price="parseFloat(sale.totalPrice).toFixed(2)"
                :total-tax="parseFloat(sale.totalTax).toFixed(2)"
                :total-discount="parseFloat(sale.totalDiscount).toFixed(2)"
                :sub-total="parseFloat(sale.subTotal).toFixed(2)"
                @updateData="calcTotalSale"
                @cancelExtraData="showExtraData=false"
              />
              <bills-sale-buy :sale="sale"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <sale-buttons-actions :sale="sale" @handleClose="handleClose()"/>
    </v-card>
    <new-box v-if="this.$store.state.boxes.showNewModal" :shop="sale.shop"/>
    <new-type-order v-if="this.$store.state.typeOrder.showNewModal" :shop="sale.shop"/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import utils from '../../../util'
import localCategories from '../../../data/categories'
import ArticleSale from './manager-sale/ArticleSale'
import CartArticle from './manager-sale/CartArticle'
import ExtraData from './manager-sale/ExtraData'
import NewBox from '../../boxes/commerce/NewBox'
import NewTypeOrder from '../../type_order/commerce/NewTypeOrder'
import BillsSaleBuy from '../../accounting-bills/BillsSaleBuy'
import NoSubcription from '../../../components/core/NoSubcription'
import SaleButtonsActions from './manager-sale/SaleButtonsActions'

export default {
  name: 'ManagerSale',
  components: { SaleButtonsActions, NoSubcription, BillsSaleBuy, NewTypeOrder, NewBox, ExtraData, CartArticle, ArticleSale },
  props: {
    managerSale: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      tax: null,
      txtFilter: '',
      page: 0,
      showListArticles: true,
      search: '',
      menu: false,
      formRule: this.$rules,
      showCart: false,
      panels: [0],
      formValid: false,
      sale: {
        no_facture: '',
        online: false,
        box: null,
        table: null,
        state: 'open',
        payments: [],
        type_order: null,
        taxes: [],
        discounts: [],
        modifiers: [],
        sale_article_shops: [],
        shop: null,
        client: null,
        bills: [],
        totalPrice: 0.00,
        totalDiscount: 0.00,
        totalTax: 0.00,
        subTotal: 0.00,
        totalDelivery: 0.00,
        totalPerquisite: 0.00,
        totalModifier: 0.00
      },
      printer: 'ticket',
      loadingData: false,
      loadingArticle: false,
      existArticles: true,
      shops: [],
      localDiscounts: [],
      localTaxes: [],
      modifiers: [],
      allBoxes: [],
      allTables: [],
      clients: [],
      allCategories: [],
      allActivated: [],
      payments: [],
      category: {},
      facture: null
    }
  },
  computed: {
    ...mapState('sale', ['editSale', 'showShowModal', 'isActionInProgress', 'validSale']),
    ...mapGetters('statics', ['smallScreen']),
    ...mapState('discount', ['discounts']),
    ...mapState('tax', ['taxes']),
    ...mapState('auth', ['cantBox', 'company_subscription']),
    ...mapState('boxes', ['boxes']),
    ...mapState('typeOrder', ['typeOrders']),
    ...mapGetters('sale', ['getNewData']),
    ...mapGetters('auth', ['user', 'access_permit']),
    ...mapState('articleShop', ['articles_shops']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getCategoriesLanguege () {
      const localCat = localCategories[this.$vuetify.lang.current]()
      let newLocal = []
      if (this.allActivated.length > 0) {
        newLocal = this.updateActiveCat(localCat, newLocal)
        this.allActivated.forEach((v) => {
          const cat = this.allCategories.filter(a => parseInt(a.id) === parseInt(v))[0]
          if (cat.company_id) {
            cat.children = []
            if (cat.parent_id) {
              this._find(newLocal, 'id', cat.parent_id, cat)
            } else {
              newLocal.push(cat)
            }
          }
        })
      }
      return newLocal
    },
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
    getCategoryName () {
      return this.category.category ? this.UpperCaseFirstLetter(this.category.category) : null
    },
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    factureNumber: {
      get () {
        return this.facture
      },
      set (newNumber) {
        return newNumber
      }
    }
  },
  watch: {
    txtFilter: function () {
      this.articlesFilter = this.txtFilter !== ''
        ? this.localArticles.filter(
          art => art.article.name.toLowerCase().includes(this.txtFilter.toLowerCase()) ||
            (art.article.barCode !== null ? art.article.barCode.toLowerCase().includes(this.txtFilter.toLowerCase()) : false)
        ) : this.localArticles
    },
    getNewData: function () {
      this.loadingData = true
      this.shops = this.getNewData.shop
      this.clients = this.getNewData.client
      this.allCategories = this.getNewData.category.categories
      this.allActivated = this.getNewData.category.activated
      this.localTaxes = this.getNewData.tax
      this.payments = this.getNewData.payment
      this.facture = this.getNewData.no_facture
      this.localDiscounts = this.getNewData.discount
      this.initData()
      this.loadingData = false
      this.updateLocalTaxes()
    },
    taxes: function () {
      this.localTaxes = this.taxes
      this.updateLocalTaxes()
    },
    discounts: function () {
      this.localDiscounts = this.discounts
      this.changeLocalDiscounts()
    },
    modifiers: function () {
      this.getLocalModifiers()
    },
    boxes: function () {
      if (this.boxes.filter(shop => shop.id === this.sale.shop.id).length > 0) {
        this.sale.shop.boxes = this.boxes.filter(shop => shop.id === this.sale.shop.id)[0].boxes
      }
    },
    typeOrders: function () {
      const tOfOrder = []
      this.typeOrders.forEach((tOrder) => {
        if (tOrder.shops.filter(shop => shop.id === this.sale.shop.id).length > 0) { tOfOrder.push(tOrder) }
      })
      this.sale.shop.type_orders = tOfOrder
    }
  },
  async created () {
    this.loadingData = true
    this.loadingArticle = true
    if (!this.managerSale) {
      await this.getEditSale(atob(this.$route.params.hash))
      this.sale = this.editSale
      this.showCart = true
    }
    await this.getNewSaleData()
    if (this.managerSale) {
      this.calcTotalSale()
    }
    this.loadingData = false
  },
  methods: {
    ...mapActions('sale', ['getSales', 'getNewSaleData', 'validCant', 'createSale', 'updateSale', 'fetchSaleNumber', 'openShowModal', 'getEditSale']),
    ...mapActions('articleShop', ['findAllToSale']),
    ...mapActions('tax', ['getTaxes']),
    calcTotalSale () {
      this.totalArticles = 0
      this.sale.totalTax = 0
      this.sale.totalDiscount = 0
      this.sale.totalPrice = 0
      this.sale.subTotal = 0
      this.sale.sale_article_shops.forEach((v) => {
        if (v.article_shops) {
          this.sale.subTotal = parseFloat(v.totalPrice) + this.sale.subTotal
          this.totalArticles += v.cant
        }
      })
      this.sale.discounts.forEach((v) => {
        this.sale.totalDiscount += v.percent === 'true' ? this.sale.subTotal * v.cant / 100 : v.cant
      })
      this.sale.taxes.forEach((v) => {
        this.sale.totalTax += v.percent === 'true' ? (this.sale.subTotal - this.sale.totalDiscount) * v.cant / 100 : v.cant
      })
      this.sale.totalPrice = (this.sale.subTotal + parseFloat(this.sale.totalTax) - parseFloat(this.sale.totalDiscount)).toFixed(2)
      this.sale.totalPrice = parseFloat(this.sale.totalPrice).toFixed(2)
      this.updateLocalTaxes()
    },
    updateLocalTaxes () {
      this.localTaxes = this.getNewData.tax ? this.getNewData.tax.length > this.taxes.length ? this.getNewData.tax : this.taxes : this.taxes
      if (this.sale.sale_article_shops.length > 0) {
        this.sale.sale_article_shops.forEach((artS) => {
          artS.sale_article_taxes.forEach((v) => {
            this.localTaxes = this.localTaxes.filter(t => t.id !== v.tax_id && t.id !== v.id)
            this.sale.taxes = this.sale.taxes.filter(t => t.id !== v.tax_id && t.id !== v.id)
          })
        })
      }
    },
    async initData () {
      if (this.managerSale) {
        this.sale.shop = this.shops[0]
        this.sale.box = this.sale.shop.boxes.filter(bx => !bx.digital)[0]
      }
      this.changeLocalDiscounts()
      this.getLocalModifiers()
      if (this.managerSale) { this.sale.no_facture = this.generateNF() }
      // this.changeTypeOrder()
      await this.updateDataArticle()
    },
    _find (collection, key, value) {
      for (const o of collection) {
        for (const [k, v] of Object.entries(o)) {
          if (k === key && v === value.toString()) {
            return o
          }
          if (Array.isArray(v)) {
            const _o = this._find(v, key, value)
            if (_o) {
              return _o
            }
          }
        }
      }
    },
    changeLocalDiscounts () {
      this.localDiscounts.forEach((v) => {
        v.local_name = v.percent ? v.name + '(' + v.cant + '%)' : v.name + '(' + v.cant + this.getCurrency + ')'
      })
    },
    getLocalModifiers () {
      if (this.sale.shop) {
        this.sale.shop.modifiers.map((v) => {
          v.modifier_name = v.percent ? v.name + '(' + v.cant + '%)' : v.name + '(' + v.cant + this.getCurrency + ')'
        })
      }
    },
    generateNF () {
      const seqer = utils.serialMaker()
      seqer.set_prefix('F' + new Date().getFullYear() + '-')
      seqer.set_seq(this.factureNumber)
      return seqer.gensym()
    },
    async updateDataArticle () {
      this.localArticles = []
      if (this.sale.shop) {
        this.loadingArticle = true
        await this.findAllToSale({ shop: this.sale.shop.id })
        this.loadingArticle = false
        this.localArticles = this.articles_shops.filter(artS => artS.shop_id === this.sale.shop.id)
        this.existArticles = this.articles_shops.length > 0
      }
      this.articlesFilter = this.localArticles
      this.articlesFilter.map((v) => {
        const exist = this.sale.sale_article_shops.filter(sat => sat.article_shop_id === v.id)
        if (exist.length > 0) {
          v.price = exist[0].price
          v.onlinePrice = exist[0].onlinePrice
          v.cost = exist[0].cost
          v.totalTax = exist[0].totalTax
          v.totalPrice = exist[0].totalPrice
        }
      })
    },
    handleClose () {
      this.localArticles = []
      this.articlesFilter = []
      this.sale.sale_article_shops = []
      this.$router.push({ name: 'vending' })
    },
    changeShop () {
      this.sale.box = this.sale.shop.boxes.filter(bx => !bx.digital)[0]
      this.updateDataArticle()
      this.clearArticles()
      this.getLocalModifiers()
    },
    clearArticles () {
      this.sale.sale_article_shops = []
      this.totalArticles = 0
    },
    UpperCaseFirstLetter (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    selectCategory (item) {
      this.category = item
      this.articlesFilter = this.localArticles.filter(art => art.article.category_id === parseInt(item.id))
      this.menu = false
      this.page = 0
    },
    clearCategory () {
      this.articlesFilter = this.localArticles
    },
    updateActiveCat (collection, newLocal) {
      for (const o of collection) {
        const ele = {
          category: o.category,
          id: o.id,
          children: []
        }
        if (o.children && Array.isArray(o.children) && o.children.length > 0) {
          const _o = this.updateActiveCat(o.children, ele.children)
          if (!Array.isArray(_o)) {
            ele.children.push(_o)
          }
        }
        if (this.allActivated.filter(act => parseInt(act) === parseInt(ele.id)).length > 0 || ele.children.length > 0) {
          if (!Array.isArray(ele)) {
            newLocal.push(ele)
          }
        }
      }
      return newLocal
    },
    selectArticle (item, variantCost) {
      if (item) {
        let artS = {}
        if (this.sale.sale_article_shops.filter(art => art.article_shops ? art.article_shops.article.id === item.article.id : false).length === 0) {
          artS = {
            article_shops: item,
            sale_variant_cost: [{
              variant_cost_id: variantCost.id,
              ref: variantCost.ref,
              cant: 1
            }],
            article_shop_id: item.id,
            cant: item.cant ? item.cant : 1,
            subTotal: item.price,
            totalCost: item.article.cost,
            totalPrice: item.price,
            totalModifier: 0.00,
            totalDiscount: 0.00,
            sale_article_taxes: item.article.taxes.length === 0 ? [] : this.getSaleArticleTaxes(item.article.taxes),
            modifiers: [],
            discounts: []
          }
          this.sale.sale_article_shops.push(artS)
        } else {
          this.sale.sale_article_shops.forEach((artShops) => {
            if (artShops.article_shops.article_id === item.article_id) {
              artS = artShops
              if (artS.sale_variant_cost.filter((vc) => vc.variant_cost_id === variantCost.id).length > 0) {
                artS.sale_variant_cost.map((vc) => {
                  if (vc.variant_cost_id === variantCost.id) {
                    vc.cant += 1
                  }
                })
              } else {
                artS.sale_variant_cost.push({
                  variant_cost_id: variantCost.id,
                  ref: variantCost.ref,
                  cant: 1
                })
              }
              artShops.cant += item.cant ? item.cant : 1
              artShops.totalCost += artShops.article_shops.article.cost * artShops.cost
            }
          })
        }
        this.showCart = true
        this.articleCalcTotalPrice(artS)
      }
    },
    articleCalcTotalPrice (saleArticleShops) {
      saleArticleShops.cant = 0
      saleArticleShops.sale_variant_cost.forEach((varCost) => {
        saleArticleShops.cant += parseFloat(varCost.cant)
      })
      saleArticleShops.subTotal = parseFloat(saleArticleShops.cant * (saleArticleShops.price ? saleArticleShops.price : saleArticleShops.article_shops.price))
      saleArticleShops.totalModifier = this.articleTotalModifier(saleArticleShops)
      saleArticleShops.totalDiscount = 0
      saleArticleShops.discounts.forEach((v) => {
        saleArticleShops.totalDiscount += v.percent ? (saleArticleShops.subTotal + saleArticleShops.totalModifier) * v.cant / 100 : v.cant
      })
      saleArticleShops.totalTax = 0
      saleArticleShops.sale_article_taxes.forEach((tax) => {
        saleArticleShops.totalTax += tax.percent ? (saleArticleShops.subTotal + saleArticleShops.totalModifier - saleArticleShops.totalDiscount) * tax.cant / 100 : tax.cant
      })
      saleArticleShops.totalPrice = parseFloat(saleArticleShops.subTotal - saleArticleShops.totalDiscount + saleArticleShops.totalModifier + saleArticleShops.totalTax).toFixed(2)
      this.calcTotalSale()
    },
    articleTotalModifier (articleShops) {
      let modf = 0
      articleShops.modifiers.forEach((v) => {
        modf += (v.percent || v.percent === '1' || v.percent === 'true') ? articleShops.cant * articleShops.article_shops.price * v.cant / 100 : v.cant
      })
      return modf
    },
    updateSaleData (sale) {
      this.sale = sale
      this.calcTotalSale()
    },
    getSaleArticleTaxes (taxes) {
      const lTax = []
      taxes.forEach(tx => {
        lTax.push({
          tax_id: tx.id,
          name: tx.name,
          name_complete: tx.name_complete,
          values_id: tx.values_id,
          type: tx.type,
          cant: tx.cant,
          percent: tx.percent
        })
      })
      return lTax
    }
  }
}
</script>

<style scoped>

</style>
