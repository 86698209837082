<template>
  <v-card>
    <v-card-text>
      <v-data-table
        style="overflow-y: auto;"
        :headers="headers"
        :items="sale.sale_article_shops"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="article_shops.article.nameComplete"
        show-expand
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>{{$vuetify.lang.t('$vuetify.sale.cart') + ' ' + subTotal + ' ' + user.company.currency }}
            </v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('clearArticles')"
                >
                  mdi-cart-off
                </v-icon>
              </template>
              <span>{{$vuetify.lang.t('$vuetify.sale.clear_cart')}}</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.cant="{ item }">
          <template v-if="item.article_shops">
            {{ parseFloat(item.cant).toFixed(2) }} {{item.article_shops.article.um.name}}
          </template>
          <template v-else>
                    <span  style="color: red" class="text-decoration-line-through">
                    {{ parseFloat(item.cant).toFixed(2) }} {{item.article_shops.article.um.name}}
                    </span>
          </template>
        </template>
        <template v-slot:item.id="{ item }">
          <template v-if="item.article_shops">
            {{UpperCaseFirstLetter(item.article_shops.article.nameComplete)}} {{ item.article_shops.um? '('+ item.article_shops.article.um.name + ')':'' }}
          </template>
          <template v-else>
                    <span  style="color: red" class="text-decoration-line-through">
                    {{UpperCaseFirstLetter(item.article_shops_deleted.article_deleted.nameComplete)}}
                    </span>
          </template>
        </template>
        <template v-slot:item.subTotal="{ item }">
          <template  v-if="item.article_shops">
            {{ online ? parseFloat(item.totalOnlinePrice).toFixed(2) : parseFloat(item.totalPrice).toFixed(2)}}
            <v-tooltip bottom class="papadear">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
              {{$vuetify.lang.t('$vuetify.actions.delete')}}
            </v-tooltip>
          </template>
          <template  v-else>
                      <span  style="color: red" class="text-decoration-line-through">
                        {{ online ? parseFloat(item.totalOnlinePrice).toFixed(2) : parseFloat(item.totalPrice).toFixed(2)}}
                      </span>
            <v-tooltip bottom class="parpadear">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  @click="deleteItem(item)">mdi-delete</v-icon>
              </template>
              {{$vuetify.lang.t('$vuetify.actions.delete')}}
            </v-tooltip>
          </template>
        </template>
        <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <v-btn
            v-if="item.article_shops"
            color="primary"
            fab
            x-small
            dark
            @click="expand(!isExpanded)"
          >
            <v-icon v-if="isExpanded">
              mdi-chevron-up
            </v-icon>
            <v-icon v-else>
              mdi-chevron-down
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            fab
            x-small
            disabled
            color="red"
          >
            <v-icon>
              mdi-close-circle
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" >
            <v-row>
              <v-col md="4" v-for="(variantCost, i) in item.sale_variant_cost" :key="variantCost.id">
                <v-list-item :key="i">
                  <v-list-item-avatar>
                    <v-icon @click="minusArticle(item, variantCost)">
                      mdi-minus-circle-outline
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-text-field-money
                      v-model="variantCost.cant"
                      :label="item.sale_variant_cost.length > 1 ? variantCost.ref + ': ' + $vuetify.lang.t('$vuetify.variants.cant') : $vuetify.lang.t('$vuetify.variants.cant')"
                      :properties="{clearable: false}"
                      :options="{ length: 15, precision: 2, empty: 0.00}"
                      @blur="$emit('updateArticle', item)"
                    />
                  </v-list-item-content>
                  <v-list-item-avatar>
                    <v-icon @click="plusArticle(item, variantCost)">
                      mdi-plus-circle-outline
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-avatar v-if="item.sale_variant_cost.length > 1">
                    <v-icon @click="deleteVariantCost(item, variantCost)">
                      mdi-delete
                    </v-icon>
                  </v-list-item-avatar>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="4" style="margin-top: 10px">
                <v-select
                  v-model="item.sale_article_taxes"
                  multiple
                  :items="taxes"
                  :label="$vuetify.lang.t('$vuetify.access.access.manager_tax')"
                  item-text="name"
                  return-object
                  @change="$emit('updateArticle', item)"
                >
                </v-select>
              </v-col>
              <v-col md="4">
                <v-autocomplete
                  v-model="item.discounts"
                  chips
                  multiple
                  :items="discounts"
                  :label="$vuetify.lang.t('$vuetify.access.access.manager_discount')"
                  item-text="name_complete"
                  return-object
                  @change="$emit('updateArticle', item)"
                >
                </v-autocomplete>
              </v-col>
              <v-col md="4">
                <v-autocomplete
                  v-model="item.modifiers"
                  chips
                  multiple
                  :items="modifiers"
                  :label="$vuetify.lang.t('$vuetify.access.access.manager_mod')"
                  item-text="name_complete"
                  return-object
                  @change="$emit('updateArticle', item)"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'CartArticle',
  props: {
    online: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    totalPrice: {
      type: String,
      default: '0.00'
    },
    totalTax: {
      type: String,
      default: '0.00'
    },
    totalDiscount: {
      type: String,
      default: '0.00'
    },
    logo: {
      type: String,
      default: ''
    },
    subTotal: {
      type: String,
      default: '0.00'
    },
    sale: {
      type: Object,
      default: function () {
        return {
          no_facture: '',
          pay: '',
          online: false,
          box: null,
          state: 'open',
          discounts: [],
          taxes: [],
          payments: null,
          article_shops: [],
          shop: null,
          client: null
        }
      }
    },
    discounts: {
      type: Array,
      default: function () {
        return []
      }
    },
    modifiers: {
      type: Array,
      default: function () {
        return []
      }
    },
    taxes: {
      type: Array,
      default: function () {
        return []
      }
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      expanded: [],
      singleExpand: false,
      show: false,
      drawer: true,
      mini: true
    }
  },
  computed: {
    ...mapState('sale', ['managerSale']),
    ...mapGetters('auth', ['user']),
    headers () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.report.cant').toUpperCase(),
          value: 'cant',
          with: '20%',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.menu.article').toUpperCase(),
          value: 'id',
          with: '60%',
          select_filter: true
        },
        {
          text: '$$$',
          value: 'subTotal',
          with: '20%',
          select_filter: true
        }
      ]
    }
  },
  watch: {
    'sale.article_shops': function () {
      if (this.sale.article_shops.length === 0) {
        this.dialog = false
      }
    },
    modifiers: function () {
      this.modifiers.forEach((v) => {
        this.localModifiers.push({
          id: v.id,
          name: v.percent ? v.name + '(' + v.value + '%)' : v.name + '(' + this.user.company.currency + v.value + ')',
          value: v.value,
          percent: v.percent
        })
      })
    }
  },
  methods: {
    UpperCaseFirstLetter (str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    },
    minusArticle (saleArticleShop, variantCost) {
      if (variantCost.cant - 1 > 0) {
        variantCost.cant = parseFloat(variantCost.cant) - 1
        saleArticleShop.cant = parseFloat(saleArticleShop.cant) - 1
      }
      this.$emit('updateArticle', saleArticleShop)
    },
    plusArticle (saleArticleShop, variantCost) {
      variantCost.cant = parseFloat(variantCost.cant) + 1
      saleArticleShop.cant = parseFloat(saleArticleShop.cant) + 1
      this.$emit('updateArticle', saleArticleShop)
    },
    deleteVariantCost (saleArticleShop, variantCost) {
      saleArticleShop.sale_variant_cost = saleArticleShop.sale_variant_cost.filter((vc) => vc.variant_cost_id !== variantCost.variant_cost_id)
      saleArticleShop.cant = saleArticleShop.cant - variantCost.cant
      this.$emit('updateArticle', saleArticleShop)
    },
    deleteItem (saleArticleShop) {
      this.sale.sale_article_shops = this.removeElement(this.sale.sale_article_shops, saleArticleShop)
      this.$emit('updateArticle', saleArticleShop)
    },
    removeElement (arr, value) {
      return arr.filter(function (ele) {
        return ele !== value
      })
    }
  }
}
</script>

<style scoped>

</style>
